<template>
    <div class="section-container">
        <div class="banner-container mx-auto">
            <div class="background-blue blue-banner text-white bold-text d-md-none py-3 text-center">
                ¡Consigue hasta $120,000 en 1 día!
            </div>
            <div class="form-container px-md-4  pb-2 pt-md-0 pb-md-4 pt-lg-3 pb-lg-5 mx-4 mx-md-0"
                 id="banner-form">
                <p class="text-center text-blue my-3 bold-text text-offer">
                    Obtén una oferta en tiempo real
                </p>
                <div class="form-banner">
                    <p>Estamos registrados ante </p>
                    <img src="@/assets/img/home/profeco-logo.svg"
                         alt="logo de profeco">
                </div>
                <!-- Formulario -->
                <div class="d-flex form-container-inputs">
                    <div class="row form-monto-container">
                        <SelectMonto />
                    </div>
                    <div class="row justify-content-center container forms-container">
                        <div class="col-12 col-md-6 col-lg-6 col-xl-5 p-1 align-self-center">
                            <div class=" mt-2 mt-md-0 mb-2 mb-lg-0">
                                <SelectMarca :errorFlag="errors.marca"
                                             customClass="bg-white" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 p-1 align-self-center">
                            <div class=" mt-2 mt-md-0 mb-2 mb-lg-0">
                                <SelectModelo :errorFlag="errors.modelo"
                                              customClass="bg-white" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-3  mt-xl-0 p-1 align-self-center">
                            <div class=" ">
                                <SelectAnoMatriculacion :errorFlag="errors.anio"
                                                        customClass="bg-white" />
                            </div>
                        </div>
                        <div class=" col-md-6 col-lg-6 col-xl-5  d-lg-block p-1">
                            <div class="pr-md-0  my-2 my-lg-0 mt-xl-0 mb-2">
                                <InputKilometraje :forceLabel="false"
                                                  :errorFlag="errors.kms"
                                                  textClass="text-question" />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-12 col-xl-7  d-none d-lg-block p-1">
                            <div class=" mb-2 ">
                                <SelectFactura :errorFlag="errors.factura"
                                               customClass="bg-white" />
                            </div>
                        </div>
                        <div class="col-12 d-lg-none mb-0 mb-md-3">
                            <div class="my-2">
                                <ButtonsInput titulo="¿Tienes la factura original?"
                                              :options="[{ value: 'si', label: 'Sí' }, { value: 'no', label: 'No' }]"
                                              :errorFlag="errors.factura"
                                              :value="typeof (factura) !== 'string' ? String(factura) : factura"
                                              :updateValue="setTitular" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="button-container text-center">

                <div class="text-center mt-5 mb-2 mt-md-0">
                    <!-- Action Form button -->
                    <ButtonConfirm text="Solicitar préstamo"
                                   :icon="false"
                                   :clickButton="onClickSolicitarPrestamo" />
                </div>
                <p class="text-blue mt-1 regular-text d-none d-md-block bold-text mb-5">
                    Obtén una oferta en tiempo real. La solicitud es gratis y sin compromiso.
                </p>
            </div>
        </div>
        <PerksComponent></PerksComponent>
    </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapState } from "vuex";

import SelectMarca from '@/components/FormularioMexico/Inputs/SelectMarca.vue';
import SelectModelo from '@/components/FormularioMexico/Inputs/SelectModelo.vue';
import SelectAnoMatriculacion from '@/components/FormularioMexico/Inputs/SelectAnoMatriculacion.vue';
import InputKilometraje from '@/components/FormularioMexico/Inputs/InputKilometraje.vue';
import ButtonsInput from '@/components/FormularioMexico/Inputs/ButtonsInput.vue';
import ButtonConfirm from '@/components/FormularioMexico/Inputs/ButtonConfirm.vue';
import SelectFactura from "@/components/FormularioMexico/Inputs/SelectFactura.vue";
import SelectMonto from "../FormularioMexico/Inputs/SelectMonto.vue";
import PerksComponent from "../PerksComponent.vue";

// import { toRaw } from 'vue'

export default {
    name: 'SectionForm',
    data() {
        return {
            loader: false,
            errors: {
                marca: false,
                modelo: false,
                anio: false,
                kms: false,
                factura: false
            },
            options: {
                marcas: [],
                modelos: [],
                anios: []
            },
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró de Crédito.',
                }

            ]
        }
    },
    store,
    computed: mapState({
        prestamo: (state) => state.preaprobado.monto,
        coche: (state) => state.preaprobado.coche,
        formulario: (state) => state.formulario,
        marca: (state) => state?.formulario?.data_matricula?.marca,
        modelo: (state) => state?.formulario?.data_matricula?.modelo,
        anio: (state) => state?.formulario?.data_matricula?.fecha_matriculacion,
        kms: (state) => state?.formulario?.kms,
        factura: (state) => state?.formulario?.titular,
        monto: (state) => state?.formulario?.monto,
    }),
    components: {
        SelectMarca,
        SelectModelo,
        SelectAnoMatriculacion,
        InputKilometraje,
        ButtonsInput,
        ButtonConfirm,
        SelectFactura,
        SelectMonto,
        PerksComponent
    },
    methods: {
        // Función para redireccionar a página de error
        moveToErrorPage: function (text) {
            localStorage.setItem('error', text)
            this.$router.push({
                path: "/error",
                hash: null,
            });
        },
        // Llamada a la API para tasar
        tasar: async function () {
            store.commit('setPreload', true)
            const { anio, modelo, marca, kms, factura,monto } = this
            const data = { marca, modelo, anio, kms, factura,importe:monto };
            axios.post(`${process.env.VUE_APP_AXIOS_BASE_URL}mexico/tasar-preaprobacion`, data)
                .then((resp) => {
                    store.commit('setPreload', false)
                    const { errors, status, monto, error, coche, rechazo } = resp.data
                    this.loader = false;
                    if (status === 400) {
                        // Errores de validación
                        Object.keys(errors).forEach(element => {
                            this.errors[element] = true;
                        })
                    } else if (status !== 200) {
                        // Regular errors
                        this.moveToErrorPage(error);
                    } else {
                        if (parseFloat(monto) < 0) {
                            this.moveToErrorPage('Lo sentimos, tu vehículo excede el kilometraje permitido.');
                        } else {
                            // Consulta exitosa, se agrega a localstorage y se redirecciona a preparobado
                            store.commit('setPreaprobadoMonto', parseFloat(monto));
                            store.commit('setPreaprobadoCoche', coche)

                            localStorage.setItem('form', JSON.stringify({
                                monto: parseFloat(monto),
                                coche,
                                rechazo,
                                ...data
                            }))

                            const eventText = 'formulario_enviado';
                            window.dataLayer = window.dataLayer || [];
                            if (!this.validateEvent(eventText)) {
                                window.dataLayer.push({
                                    event: eventText,
                                });
                            }

                            this.$router.push({
                                path: '/preaprobado',
                                hash: null,
                            });
                        }
                    }
                }).catch(() => {
                    // Error del servidor
                    store.commit('setPreload', false)
                    this.moveToErrorPage('Ocurrió un error desconocido, intenta más tarde.');
                })
        },
        onClickSolicitarPrestamo: async function () {
            const { anio, modelo, marca, kms, factura } = this
            // Validaciones
            await this.validateKms(kms);
            await this.validateSelector(marca, 'marca');
            await this.validateSelector(modelo, 'modelo');
            await this.validateSelector(anio, 'anio');
            await this.validateFactura(factura)
            let flag = false;
            for (const error in this.errors) {
                flag = this.errors[error] || flag;
            }
            if (!flag) {
                await this.tasar();
            }
        },
        validateKms: function (value) {
            if (!(value < 1000000 && value > 0)) {
                this.errors.kms = true
            } else {
                this.errors.kms = false
            }
        },
        validateSelector: function (value, field, oldValue = null) {
            if (value === 0) {
                this.errors[field] = true;
            } else {
                if (field === 'marca') {
                    // El evento datos_auto solo se ejecutará una vez cuando se seleccione un valor para la marca, detectando que fue iniciado el proceso
                    const eventText = 'datos_auto';
                    window.dataLayer = window.dataLayer || [];
                    if (!this.validateEvent(eventText)) {
                        window.dataLayer.push({
                            event: eventText,
                        });
                    }

                    if (oldValue === 0) {
                        this.errors.modelo = false;
                        this.errors.anio = false;
                    }
                }
                this.errors[field] = false;
            }
        },
        validateFactura: function (value) {
            if (value === 'si' || value === 'no') {
                const eventText = 'factura_auto';
                window.dataLayer = window.dataLayer || [];
                if (!this.validateEvent(eventText)) {
                    window.dataLayer.push({
                        event: eventText,
                    });
                }
                this.errors.factura = false;
            } else {
                this.errors.factura = true;
            }
        },
        setTitular: function (value) {
            store.commit('setTitular', value)
        },
        // Valida si el evento existe
        validateEvent: function (eventText) {
            const filtrado = window.dataLayer.filter(element => element?.event == eventText);
            return filtrado.length > 0;
        }
    },
    watch: {
        'marca': function (value, oldvalue) {
            this.validateSelector(value, 'marca', oldvalue)
        },
        'modelo': function (value, oldvalue) {
            this.validateSelector(value, 'modelo', oldvalue)
        },
        'anio': function (value, oldvalue) {
            this.validateSelector(value, 'anio', oldvalue)
        },
        'kms': function (value) {
            this.validateKms(value)
        },
        'factura': function (value) {
            this.validateFactura(value)
        }
    },
    created: function () {
        // Reinicia lo solicitud (el formulario)
        store.commit('resetSolicitud')
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.section-container {
    font-family: 'Montserrat';
}

.monto-form-container {
    width: 100%;

}

.form-container-inputs {
    flex-direction: column;
}

.text-offer {
    font-size: 1.1rem
}

.form-banner {
    color: white;
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-bottom: 2rem;
    font-size: 2rem;
    -webkit-box-shadow: 0px 6px 14px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 6px 14px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 6px 14px -5px rgba(0, 0, 0, 0.75);
    padding: 0.6rem;
    align-self: center;

    p {
        margin: 0 !important;
    }

    img {
        height: 2rem;
    }
}

.banner-container {
    width: 85%;
}

.banner-image {
    min-width: 130%;
    left: -31%;
    z-index: -1;
    bottom: -25px;
}

.form-container {
    background-color: $blue;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}

.button-container {
    margin-top: -1.25rem;
}

// Card
.card {
    border-radius: 0.625rem;
    border: 1px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40));
    background: rgba(255, 255, 255, 0.90);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
}

.background-benefits {
    background-color: #FFA5000A;
}

// Button

// Color
.background-orange {
    background-color: $orange;
}

.background-blue {
    background-color: $blue;
}

// Text
.bold-text {
    font-family: 'Montserrat-Bold';
    font-weight: bold;
}

.text-blue {
    color: $blue;
}

.titulo {
    font-size: 43px;
    line-height: 60px;
    text-align: left;
}

.subtitulo {
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 28px;
    line-height: 48px;
}

.regular-text {
    font-family: 'Montserrat';
    font-size: 16px;
}

.card-text {
    font-family: "Montserrat-Medium";
    font-weight: 600;
    margin-top: 6px;
    margin-bottom: 0;
    font-size: 16px;
}

// form

// Image
.image-responsive {
    height: 280px;
    width: auto;
    margin-left: -70px;
    margin-bottom: -30px;
    z-index: -1;
}

.form-control {
    background-color: $white;
}

@include media-breakpoint-up(lg) {
    .background-benefits {
        background-color: white;
    }

    .form-monto-container {
        width: 40%;
    }

    .form-banner {
        color: white;
    }


    .form-monto-container {
        width: 60%;
    }

    .form-container-inputs {
        flex-direction: row;
    }

    .card-text {
        font-size: 1rem;
    }
}

.text-offer {
    display: none;
}

@media screen and (max-width:768px) {
    .text-offer{
        margin: 2rem 0rem 1rem 0rem !important;
        display: block !important;
    }
}

@include media-breakpoint-down(xs) {
    .background-benefits{
        padding: 1rem;
    }
}

@include media-breakpoint-down(md) {
    .form-container {
        background-color: white;
    }

    .form-banner {
        display: none;
    }

    .banner-container {
        width: 100%;
    }

    .titulo {
        font-size: 20px;
        line-height: 1.1;
        text-align: left;
    }

    .subtitulo {
        font-size: 13px;
        line-height: 1.1;
    }
}
</style>